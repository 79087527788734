<template>
	<div>
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item :label="viewType ? '驻派名称：' : '巡诊名称：'">
					<el-autocomplete v-model.trim="formInline.name" :fetch-suggestions="querySearchAsync" clearable placeholder="请输入内容"></el-autocomplete>
				</el-form-item>
				<el-form-item label="省：">
					<el-select
						v-model="formInline.economize"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 2"
						placeholder="请选择省"
						@change="changeEconomize($event, 1)"
					>
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：">
					<el-select
						v-model="formInline.market"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 4"
						placeholder="请选择市"
						@change="changeEconomize($event, 2)"
					>
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select
						v-model="formInline.district"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 6"
						placeholder="请选择区县"
						@change="changeEconomize($event, 3)"
					>
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="乡镇：">
					<el-select
						v-model="formInline.townCode"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 9"
						placeholder="请选择"
						@change="changeEconomize($event, 4)"
					>
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="村：">
					<el-select
						v-model="formInline.villageCode"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 12"
						placeholder="请选择"
						
					>
						<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="viewType ? '驻派状态：' : '巡诊状态：'">
					<el-select v-model="formInline.state" placeholder="请选择">
						<el-option v-for="item in traningStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="viewType ? tableHeader : tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:operation-column-width="100"
				:is-show-selection="false"
				:showOperation="false"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #account="{ scope }">
					<el-button @click="accountInfoHand(scope.row)" size="mini" type="text"> 账号信息 </el-button>
				</template>
				<template #seeMore="{ scope }">
					<el-button class="seeMore" type="text" size="mini" @click="seeMore(scope.row)">查看</el-button>
				</template>
			</Table>
		</div>
		<AccountInfo ref="accountInfo" :isShowDetail="true"></AccountInfo>
		<show ref="show" :checkRow="checkRow"></show>
	</div>
</template>

<script>
export default {
	name: 'patrolStatistics',
	components: {
		Table: () => import('@/components/Table/table'),
		AccountInfo: () => import('@/views/staticTotalView/components/newDialog.vue'),
		show: () => import('./show.vue'),
	},
	props: ['viewType', 'tableHeader'],
	data() {
		return {
			formInline: {
				name: '',
				economize: '',
				market: '',
				district: '',
				townCode: '',
				villageCode: '',
				state: '',
			},
			tableHead: [
				{
					label: '巡诊名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '巡诊组名称',
					prop: 'medicalWorkerGroup',
					formatter: (row) => {
						return row.medicalWorkerGroup?.title || '-';
					},
				},
				{
					label: '巡诊开始时间',
					prop: 'startTime',
					width: 160,
					formatter: (row) => {
						return row.startTime || '-';
					},
				},
				{
					label: '巡诊结束时间',
					prop: 'endTime',
					width: 160,
					formatter: (row) => {
						return row.endTime || '-';
					},
				},

				{
					label: '巡诊范围',
					prop: 'resourceAllocations',
					formatter: (row) => {
						if (!row?.resourceAllocations || row?.resourceAllocations.length === 0) return '-';
						let str = '';
						row.resourceAllocations.forEach((item) => {
							if (item.regions.length > 0) {
								let str1 = '';
								item.regions.forEach((itr1) => {
									str1 += itr1?.title || '';
								});
								str += str1 + ';';
							}
						});
						return str;
					},
				},
				{
					label: '配置资源',
					prop: 'seeMore',
				},
				{
					label: '巡诊状态',
					prop: 'state',
					formatter: (row) => {
						return row.state == 'U' ? '未开始' : row.state == 'P' ? '进行中' : '已结束';
					},
				},
				{
					label: '所属账号',
					prop: 'account',
				},
			],
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			districtList: [], //区县
			marketList: [], //市
			townList: [],
			economizeList: [], //省
			options: [],
			traningStatusList: [
				{ label: '全部', value: '' },
				{ label: '未开始', value: 'U' },
				{ label: '进行中', value: 'P' },
				{ label: '已结束', value: 'F' },
			],
			villageList: [],
			checkRow: {},
			userInfo: {},
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	watch: {
		viewType: {
			handler(val) {
				this.getList();
			},
			deep: true,
		},
	},
	mounted() {
		this.getEconomizeList();
		this.userInfo = JSON.parse(sessionStorage.getItem('userInfo')) || {};
		switch (this.userInfo.regionCode.length) {
			case 2:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				break;
			case 4:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				break;
			case 6:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				break;
			case 9:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				this.formInline.townCode = this.userInfo.chinaRegionDtoList[3].code;
				this.changeEconomize(this.userInfo.regionCode, 4);
				break;
			case 12:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				this.formInline.townCode = this.userInfo.chinaRegionDtoList[3].code;
				this.changeEconomize(this.userInfo.regionCode, 4);
				this.formInline.villageCode = this.userInfo.chinaRegionDtoList[4].code;
				break;
			default:
				break;
		}
		this.getList();
	},
	methods: {
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			switch (type) {
				case 1:
					this.formInline.market = null;
					this.formInline.district = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.marketList = [];
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.district = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 4:
					this.formInline.villageCode = null;
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.villageList = res.data.collection || [];
								this.villageList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 查询条件
		onSearch() {
			this.queryData.current = 1;
			this.getList('');
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? '' : (this.loading = true);
			const { name, economize, market, district, townCode, villageCode, state } = this.formInline;
			let code =
				economize && !market && !district && !townCode && !villageCode
					? economize
					: economize && market && !district && !townCode && !villageCode
					? market
					: economize && market && district && !townCode && !villageCode
					? district
					: economize && market && district && townCode && !villageCode
					? townCode
					: economize && market && district && townCode && villageCode
					? villageCode
					: null;
			let data = {
				name: name,
				villageCode: code,
				state: state,
				...this.queryData,
				discriminationCode: this.viewType ? 'D' : 'T',
			};
			this.$http
				.get(this.api['TourPlans#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.name,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		accountInfoHand(row) {
			if (row.regionAccount?.id) {
				let data = {
					...row.regionAccount,
					chinaRegionDtoList: row.regions,
				};
				this.$refs.accountInfo.init(data);
			}
		},
		seeMore(item) {
			this.checkRow = item;
			this.$refs.show.showVisible = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.demo-form-inline {
	/deep/ .el-select {
		width: 120px !important;
		.el-input {
			width: 120px !important;
			.el-input__inner {
				width: 120px !important;
			}
		}
	}
	/deep/ .el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>